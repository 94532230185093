import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "nodejs-patterns",
  "date": "2016-06-02",
  "title": "NODEJS PATTERNS",
  "author": "admin",
  "tags": ["development", "node.js"],
  "featuredImage": "feature.jpg",
  "excerpt": "A design pattern is a general, reusable solution to a commonly occurring problem. In this article we are going to look at some common design patterns we can use for Node.js."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Design patterns we are going to investigate are:`}</p>
    <ul>
      <li parentName="ul">{`Dependency Injection`}</li>
      <li parentName="ul">{`Factories`}</li>
      <li parentName="ul">{`Middlewares`}</li>
      <li parentName="ul">{`Observers`}</li>
      <li parentName="ul">{`Singlestons`}</li>
      <li parentName="ul">{`Streams`}</li>
    </ul>
    <h2>{`Dependency Injection`}</h2>
    <p>{`Dependency injection is a software
design pattern in which one or more dependencies (or services) are injected, or passed by reference, into a dependent object.`}</p>
    <p>{`Let’s look at an example where we pass a database as a dependancy.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function employeeModel (opt) {
  var db;
 
  if (!opt.db) {
    throw new Error('opt.db is not defined');
  }
 
  db = opt.db;
 
  return {
    create: function (done) {
      db.query('SELECT ...', done);
    }
  }
}
 
module.exports = employeeModel;
`}</code></pre>
    <h2>{`Factories`}</h2>
    <p>{`This pattern provides us with a generic interface for creating object, so we don’t have to worry about the nitty-gritty of the complex constructors.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function Employee(opt) {
  this.opt = opt;
}
 
function create(opt) {
  // modify the options here
  return new Employee(opt);
}
 
module.exports.create = create;
`}</code></pre>
    <Message type="info" title="Factories" content="Factories make testing much easier as you can inject module dependencies using this pattern." mdxType="Message" />
    <h2>{`Middlewares`}</h2>
    <p>{`Middlewares (or pipelines) are an essential part of Express, where the output of one unit is the input of the next.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2016-06-02-nodejs-patterns/express-middleware.jpg",
        "alt": "Express Middlesware"
      }}></img></p>
    <p>{`Let’s look at an example`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var express = require('express');
var app = express();
 
app.get('/', function (req, res) {
  res.send('Hello World!');
});
 
app.listen(3000);
`}</code></pre>
    <h2>{`Observers`}</h2>
    <p>{`The observers notify the owner object on state changes automatically. In Node.js we can use `}<inlineCode parentName="p">{`EventEmitter`}</inlineCode>{` to implement this pattern.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var util = require('util');
var EventEmitter = require('events').EventEmitter;
 
function EmployeeObservable() {
  EventEmitter.call(this);
}
 
util.inherits(EmployeeObservable, EventEmitter);
`}</code></pre>
    <p>{`Now we can use this observable by adding some functionality to it.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  EmployeeObservable.prototype.login = function (name) {
  this.emit('Logged in as ', name);
};
`}</code></pre>
    <p>{`Now our observable can emit event.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var EmployeeObservable = require('EmployeeObservable');
var observable = new EmployeeObservable();
 
observable.on('Logged in as ', function (name) {
  console.log(name);
});
 
observable.login('Sam');
`}</code></pre>
    <h2>{`Singlestons`}</h2>
    <p>{`By using singleton pattern we are making sure that the number of instances of a class is restricted to one.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var PI = Math.PI;
 
function circle (radius) {
  return radius * radius * PI;
}
 
module.exports.circle = circle;
`}</code></pre>
    <p>{`Now let’s use this module to instantiate an object.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var area = require('./area');
 
console.log(area.circle(5));
`}</code></pre>
    <Message type="info" title="Most Common Node.js Pattern" content="Because require is common in Node.js, that makes the most common pattern." mdxType="Message" />
    <h2>{`Streams`}</h2>
    <p>{`Stream are like special pipelines, designed for complex operations like processing large amounts of flowing data.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`process.stdin.on('readable', function() {
  var buffer = process.stdin.read(5);
  console.dir(buffer);
  process.stdin.read(0);
});
`}</code></pre>
    <p>{`The console output will be like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`(echo abc; sleep 1; echo def; sleep 1; echo ghi) | node consume2.js
`}</code></pre>
    <p>{`Output:`}</p>
    <pre><code parentName="pre" {...{}}>{`<Buffer 61 62 63>
<Buffer 0a 64 65>
<Buffer 66 0a 67>
<Buffer 68 69 0a>
`}</code></pre>
    <h2>{`Conclusion`}</h2>
    <p>{`It is totally up to you when to use patterns in Node.js, but having this knowledge in your toolbox will come handy.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      